<template>
  <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.888672H7V1.66645H0V0.888672ZM0 3.61089H7V4.38867H0V3.61089ZM0 6.33312H7V7.11089H0V6.33312Z" fill="#D9D9D9"/>
  </svg>
</template>

<script>
export default {
  name: 'MoveIcon'
}
</script>
