<template>
  <div class="tour-menu__container" :class="{ active: $store.state.showTourMenu }">
    <div @click="closeMenu" class="tour-menu__bg"></div>
    <div class="tour-menu">
      <div class="tour-menu__header">
        Тур для страницы
      </div>
      <div class="tour-menu__body">
        <a @click.prevent="addStep" class="tour-menu__step tour-add__btn" href="#">
          <PlusIcon/>
          <span>Добавить тур</span>
        </a>
        <ul class="tour-menu__list">
          <draggable v-model="allTours" group="steps">
            <li v-for="(item, index) in allTours" :key="index">
              <div class="tour-menu__step">
                <span class="tour-menu__step-move">
                  <MoveIcon/>
                </span>
                <span class="tour-menu__step-title" v-if="item.name">{{ item.name }}</span>
                <div class="tour-menu__step-actions">
                  <span @click="editTour(item)">
                    <SettingsIcon/>
                  </span>
                  <span @click="deleteTour(item.id)" class="tour__step-delete">
                    <DeleteIcon/>
                  </span>
                </div>
              </div>
            </li>
          </draggable>
        </ul>
      </div>
      <div class="tour-menu__bottom">
        <button @click="closeMenu" class="btn btn--gray">Отмена</button>
        <button @click="closeMenu" class="btn">Сохранить</button>
      </div>
    </div>
    <v-tour
      name="pageTour"
      :steps="steps"
      :options="myOptions"
      :callbacks="callbacks"
      v-if="steps && steps.length"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <div v-if="tour.steps[tour.currentStep]" class="v-tour__container">
            <v-step
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <div slot="actions" class="v-step__buttons">
                <button @click="skipTour(tour, tour.skip)" v-if="!tour.isLast"
                        class="v-step__button v-step__button-skip">{{ myOptions.labels.buttonSkip }}
                </button>
                <button @click="previousStep(tour, tour.previousStep)" v-if="!tour.isFirst" class="btn">
                  {{ myOptions.labels.buttonPrevious }}
                </button>
                <button @click="nextStep(tour, tour.nextStep)" v-if="!tour.isLast" class="btn">
                  {{ myOptions.labels.buttonNext }}
                </button>
                <button @click="finishTour" v-if="tour.isLast" class="btn">
                  {{ myOptions.labels.buttonStop }}
                </button>
              </div>
            </v-step>
          </div>
        </transition>
      </template>
    </v-tour>
    <v-tour
      name="menuTour"
      :steps="addSteps"
      v-if="addSteps && addSteps.length"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <div class="v-tour__container">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <div slot="content" class="v-step__content tour-add__content">
                <div class="tour-add__input-wrap" :class="{ error: form.title.message }">
                  <input v-model.trim="form.title.value" type="text" placeholder="Заголовок">
                  <span class="tour-add__input-error" v-if="form.title.message">
                    {{ form.title.message }}
                  </span>
                </div>
                <div class="tour-add__input-wrap" :class="{ error: form.description.message }">
                  <textarea v-model.trim="form.description.value" rows="4" placeholder="Описание"></textarea>
                  <span class="tour-add__input-error" v-if="form.description.message">
                    {{ form.description.message }}
                  </span>
                </div>
                <div class="tour-add__input-wrap" :class="{ error: form.redirect.message }">
                  <select class="tour__routes-select" v-model="form.redirect.value">
                    <option :value="null" selected disabled>Переход на страницу</option>
                    <option v-for="(item, index) in routes" :key="index" :value="item.name">{{
                        item.meta.title
                      }}
                    </option>
                  </select>
                  <span class="tour-add__input-error" v-if="form.redirect.message">
                    {{ form.redirect.message }}
                  </span>
                </div>
                <SelectComponent
                  v-model="form.roles.value"
                  :multiple="true"
                  :options="roles"
                  label-name="title"
                  title="Выберите роли"
                />
              </div>
              <div slot="actions" class="v-step__buttons">
                <button @click="cancelAdd(tour.stop)" class="btn btn--gray">Отмена</button>
                <button @click="submitAddStep(tour.stop)" class="btn">Сохранить</button>
              </div>
            </v-step>
          </div>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import PlusIcon from '@/components/svg/plus.vue'
import SettingsIcon from '@/components/svg/settings.vue'
import DeleteIcon from '@/components/svg/DeleteIcon.vue'
import MoveIcon from '@/components/svg/MoveIcon'
import UPDATE_TOUR from '@/graphql/mutations/UpdateTour.gql'
import UPDATE_TOURS from '@/graphql/mutations/UpdateTours.gql'
import ADD_TOUR from '@/graphql/mutations/AddTour.gql'
import DELETE_TOUR from '@/graphql/mutations/DeleteTour.gql'
import VIEW_TOURS from '@/graphql/mutations/ViewTours.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import SelectComponent from '@/components/ui/select'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TourMenu',
  components: {
    SelectComponent,
    PlusIcon,
    SettingsIcon,
    DeleteIcon,
    MoveIcon
  },
  data () {
    return {
      offset: -300, // отступ от верхнего края окна
      id: null, // для редактирования
      currentStep: null,
      myOptions: {
        enabledNavigationKeys: false, // отключил, т.к. обработчики висят на button, нужно переделать
        labels: {
          buttonSkip: 'Пропустить',
          buttonPrevious: 'Назад',
          buttonNext: 'Далее',
          buttonStop: 'Завершить'
        }
      },
      callbacks: {
        onStart: this.startTourCallback
        // onPreviousStep: this.previousStepCallback,
        // onNextStep: this.nextStepCallback,
        // onStop: this.stopTourCallback,
        // onFinish: this.finishTourCallback
      },
      form: {
        title: {
          value: null,
          message: null,
          required: true
        },
        description: {
          value: null,
          message: null,
          required: true
        },
        redirect: {
          value: null,
          message: null,
          required: false
        },
        roles: {
          value: [],
          message: null,
          required: true
        }
      },
      padding: 5, // отсупы вокруг блока тура
      tours: [], // туры для текущей страницы
      steps: [], // шаги тура, непросмотренные
      addSteps: [], // для добавления/изменения
      routes: [], // список страниц для перехода
      isToursRepeat: false
    }
  },
  mounted () {
    this.setTours()
    this.getTourRoutes()
  },
  watch: {
    '$route.path' () {
      setTimeout(() => {
        this.setTours()
        this.removeTarget()
        this.$tours['pageTour'].finish()
      }, 0)
    },
    '$store.state.loadedPages' () {
      if (this.isPageLoaded) {
        this.startTour()
      }
    }
  },
  computed: {
    isPageLoaded () {
      return this.$route.meta.subscribe
        ? this.$store.state.loadedPages.find(item => item.route === this.$route.name)
        : true
    },
    allTours: {
      get: function () {
        if (this.$store.state.allTours && this.$store.state.allTours.length) {
          return this.$store.state.allTours.filter(tour => tour.page === this.$route.name).sort()
        }
        return []
      },
      set: function (newTours) {
        newTours.forEach((item, i) => {
          item.order = i + 1
        })
        this.$store.commit('allTours', newTours)
        this.updateTours(newTours)
      }
    },
    roles () {
      return this.$store.state.allRoles
    }
  },
  methods: {
    setTours () {
      this.tours =
        this.$store.state.me &&
        this.$store.state.me.tours &&
        this.$store.state.me.tours.length ? this.$store.state.me.tours.filter(tour => tour.page === this.$route.name) : []
      this.setSteps()
    },
    setSteps () {
      if (this.tours.length) {
        if (!this.isToursRepeat) {
          this.steps = this.tours.filter(t => !(t.viewed || this.$store.state.viewedTours.includes(t.id))).map(item => {
            item.target = item.block
            item.content = item.text
            item.header = {
              title: item.name
            }
            item.offset = this.offset
            item.upTarget = () => {
              this.upTarget(item.block)
            }
            item.removeTarget = () => {
              this.removeTarget(item.block)
            }
            return item
          })
        } else {
          this.steps = this.tours.map(item => {
            item.target = item.block
            item.content = item.text
            item.header = {
              title: item.name
            }
            item.offset = this.offset
            item.upTarget = () => {
              this.upTarget(item.block)
            }
            item.removeTarget = () => {
              this.removeTarget(item.block)
            }
            return item
          })
        }
      } else {
        this.steps = []
      }
    },
    // выделение блока тура
    upTarget (selector, isAdding = false) {
      document.body.classList.add('tour-active')
      let target = document.querySelector(selector)
      let tourBg = document.createElement('div')
      let topLeft = document.createElement('div')
      let topRight = document.createElement('div')
      let bottomLeft = document.createElement('div')
      let bottomRight = document.createElement('div')

      tourBg.classList.add('tour-target__bg')
      topLeft.classList.add('tour-target__bg-part', 'tour__top-left')
      topRight.classList.add('tour-target__bg-part', 'tour__top-right')
      bottomLeft.classList.add('tour-target__bg-part', 'tour__bottom-left')
      bottomRight.classList.add('tour-target__bg-part', 'tour__bottom-right')

      topLeft.style.cssText = ` left: 0px; top: 0px; right: 0px; bottom: 0px;`

      tourBg.appendChild(topLeft)
      tourBg.appendChild(topRight)
      tourBg.appendChild(bottomLeft)
      tourBg.appendChild(bottomRight)
      if (isAdding) {
        tourBg.addEventListener('click', this.skipMenuTour)
        document.addEventListener('keydown', (e) => {
          if (e.key === 'Escape') {
            this.skipMenuTour()
          }
        })
      } else {
        tourBg.addEventListener('click', this.skipTour)
        document.addEventListener('keydown', (e) => {
          if (e.key === 'Escape') {
            this.skipTour()
          }
        })
      }
      document.body.appendChild(tourBg)

      let interval = setInterval(() => {
        let rect = target.getBoundingClientRect()
        topLeft.style.cssText = ` left: 0; top: 0; width: ${rect.right + this.padding}px; height: ${rect.top - this.padding}px;`
        topRight.style.cssText = ` left: ${rect.right + this.padding}px; top: 0; right: 0; height: ${rect.bottom + this.padding}px;`
        bottomLeft.style.cssText = ` left: 0; top: ${rect.top - this.padding}px; width: ${rect.left - this.padding}px; bottom: 0;`
        bottomRight.style.cssText = ` left: ${rect.left - this.padding}px; top: ${rect.bottom + this.padding}px; right: 0; bottom: 0;`

        let step = document.querySelector('.v-step')
        let stepRect = step.getBoundingClientRect()
        // если сверху и снизу не помещается
        if (stepRect.height > rect.top - this.padding && stepRect.height > window.innerHeight - rect.bottom + this.padding) {
          if (stepRect.width < window.innerWidth - rect.right + this.padding) {
            // если справа помещается
            step.style.transform = `translate(${rect.width + this.padding + 10 + rect.left - this.padding}px, ${rect.top - this.padding + rect.height / 2 - stepRect.height / 2}px)`
            step.dataset.popperPlacement = 'right'
            step.querySelector('.v-step__arrow').style.cssText = ` left: 0; top: 50%; transform: translateY(-50%);`
          } else if (stepRect.width < rect.left - this.padding) {
            // если слева помещается
            step.style.transform = `translate(${rect.left - this.padding - stepRect.width - 10}px, ${rect.top - this.padding + rect.height / 2 - stepRect.height / 2}px)`
            step.dataset.popperPlacement = 'left'
            step.querySelector('.v-step__arrow').style.cssText = ` left: auto; right: -10px; top: 50%; transform: translateY(-50%);`
          } else if (stepRect.height + rect.height > window.innerHeight) {
            step.style.transform = `translate(${rect.left - this.padding + rect.width / 2 - stepRect.width / 2}px, ${this.padding}px)`
            step.dataset.popperPlacement = 'top'
          } else {
            step.style.transform = `translate(${rect.left - this.padding + rect.width / 2 - stepRect.width / 2}px, ${rect.bottom + this.padding - stepRect.height - 8}px)`
            step.dataset.popperPlacement = 'top'
          }
        }
      }, 10)
      setTimeout(() => {
        clearInterval(interval)
      }, 1000)
    },
    // удаление выделения блока тура
    removeTarget (selector) {
      let bg = document.querySelector('.tour-target__bg')
      bg.parentNode.removeChild(bg)
      document.body.classList.remove('tour-active')
    },
    offsetTop (selector) {
      let el = document.querySelector(selector)
      let rect = el.getBoundingClientRect()
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return rect.top + scrollTop
    },
    startTourCallback () {
      if (this.steps && this.steps.length) {
        this.upTarget(this.steps[0].block)
      }
    },
    previousStep (tour, callback) {
      this.steps[tour.currentStep].removeTarget()
      this.steps[tour.currentStep - 1].upTarget()
      callback()
    },
    nextStep (tour, callback) {
      this.steps[tour.currentStep].removeTarget()
      this.steps[tour.currentStep + 1].upTarget()
      callback()
      this.viewTours([this.steps[tour.currentStep].id])
      if (this.steps[tour.currentStep].redirect) {
        this.$router.push({ name: this.steps[tour.currentStep].redirect })
      }
    },
    skipTour () {
      let currentStep = this.$tours['pageTour'].currentStep
      this.steps[currentStep].removeTarget()
      this.$tours['pageTour'].skip()
      this.viewTours(this.steps.map(s => s.id))
      this.isToursRepeat = false
      window.scrollTo(0, 0)
      document.removeEventListener('keydown', this.skipTour)
    },
    finishTour () {
      let currentStep = this.$tours['pageTour'].currentStep
      this.steps[currentStep].removeTarget()
      this.$tours['pageTour'].finish()
      this.viewTours(this.steps.map(s => s.id))
      if (this.steps[currentStep].redirect) {
        this.$router.push({ name: this.steps[currentStep].redirect })
      } else {
        window.scrollTo(0, 0)
      }
      this.isToursRepeat = false
    },
    startTour () {
      setTimeout(() => {
        if (this.steps && this.steps.length && !this.$tours['pageTour'].isRunning) {
          this.$tours['pageTour'].start()
        }
      }, 0)
    },
    tourRepeat () {
      this.isToursRepeat = true
      this.setSteps()
      this.startTour()
    },
    getTourRoutes () {
      this.$router.options.routes.forEach(route => {
        if (route.meta.title) {
          this.routes.push(route)
        }
      })
    },
    startMenuTour () {
      if (this.addSteps && this.addSteps.length) {
        setTimeout(() => {
          this.$tours['menuTour'].start()
          this.upTarget(this.addSteps[0].block, true)
        }, 0)
      }
    },
    skipMenuTour () {
      this.removeTarget()
      this.$tours['menuTour'].skip()
      document.removeEventListener('keydown', this.skipMenuTour)
    },
    // изменение
    editTour (editStep) {
      this.addSteps = []
      this.resetForm()
      this.resetValidation()
      this.id = editStep.id
      editStep.target = editStep.block
      editStep.offset = this.offset
      this.form.title.value = editStep.name
      this.form.description.value = editStep.text
      this.form.redirect.value = editStep.redirect
      this.form.roles.value = this.roles.filter(item => editStep.role.includes(item.role))
      editStep.header = null
      this.addSteps = [editStep]
      this.startMenuTour()
      this.closeMenu()
    },
    // удаление
    async deleteTour (id) {
      if (id) {
        await this.$apollo.mutate({
          mutation: DELETE_TOUR,
          variables: {
            id: id
          }
        })
          .then(() => {
            this.loadAllTours()
            this.$notify({
              group: 'lsg-notify',
              text: 'Тур удален'
            })
          })
          .catch(() => {
            this.$notify({
              group: 'lsg-notify',
              text: 'Произошла ошибка'
            })
          })
      }
    },
    // массовое обновление туров
    async updateTours (tours) {
      if (tours && tours.length) {
        await this.$apollo.mutate({
          mutation: UPDATE_TOURS,
          variables: {
            input: tours.map(item => {
              return {
                id: item.id,
                order: item.order
              }
            })
          }
        })
          .then(() => {
            this.loadAllTours()
          })
          .catch(() => {
            this.$notify({
              group: 'lsg-notify',
              text: 'Произошла ошибка'
            })
          })
      }
    },
    // просмотр
    async viewTours (ids) {
      if (ids && ids.length) {
        await this.$apollo.mutate({
          mutation: VIEW_TOURS,
          variables: {
            ids: ids
          }
        })
          .then(() => {
            this.$store.state.viewedTours.push(...ids)
            this.loadTours()
          })
      }
    },
    // отмена добавления/изменения
    cancelAdd (callback) {
      this.removeTarget(this.addSteps[0].block)
      this.addSteps = []
      this.resetForm()
      this.resetValidation()
      this.$store.state.showTourMenu = true
      this.id = null
      callback()
    },
    // добавление обработчика нажатия для страницы
    addStep (e) {
      e.stopPropagation()
      e.stopImmediatePropagation()
      e.preventDefault()
      this.resetForm()
      this.resetValidation()
      this.addSteps = []
      this.closeMenu()
      setTimeout(() => {
        window.addEventListener('click', this.tourAddHandler, { capture: true })
        window.addEventListener('mouseover', this.changeOutline)
        window.addEventListener('mouseout', this.changeOutline)
      }, 0)
    },
    // Обработчик нажатия на любой элемент на странице, добавляющий для него тур
    tourAddHandler (e) {
      e.stopPropagation()
      e.stopImmediatePropagation()
      e.preventDefault()
      window.removeEventListener('click', this.tourAddHandler, { capture: true })
      window.removeEventListener('mouseover', this.changeOutline)
      window.removeEventListener('mouseout', this.changeOutline)
      e.target.classList.remove('tour-target--outline')
      this.addSteps = [{
        target: this.getElementId(e.target),
        block: this.getElementId(e.target),
        offset: -e.target.getBoundingClientRect().top,
        upTarget: () => {
          this.upTarget(this.getElementId(e.target))
        },
        removeTarget: () => {
          this.removeTarget(this.getElementId(e.target))
        }
      }]
      this.startMenuTour()
    },
    async submitAddStep (callback) {
      this.resetValidation()
      let errorsCount = 0
      Object.keys(this.form).forEach(key => {
        if (this.form[key].required && !this.form[key].value) {
          this.form[key].message = 'Заполните поле'
          errorsCount += 1
        }
      })
      if (!errorsCount) {
        let lastPos = this.allTours && this.allTours.length ? this.allTours[this.allTours.length - 1].order : 0
        let currentInput = {
          name: this.form.title.value,
          text: this.form.description.value,
          block: this.addSteps[0].block,
          page: this.$route.name,
          order: this.id ? this.addSteps[0].order : lastPos + 1,
          redirect: this.form.redirect.value,
          role: this.form.roles.value.map(r => r.role)
          // role: ['admin']
        }
        if (this.id) currentInput.id = this.id
        await this.$apollo.mutate({
          mutation: this.id ? UPDATE_TOUR : ADD_TOUR,
          variables: {
            input: currentInput
          }
        })
          .then(() => {
            // загрузить туры заново
            this.loadAllTours()
            this.$notify({
              group: 'lsg-notify',
              text: this.id ? 'Тур изменен' : 'Тур добавлен'
            })
            this.removeTarget(this.addSteps[0].block)
            this.$store.state.showTourMenu = true
            this.id = null
            this.addSteps = []
          })
          .catch((error) => {
            this.removeTarget(this.addSteps[0].block)
            _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
              this.$notify({
                group: 'lsg-notify',
                text: item
              })
            })
          })
        this.addSteps = []
        this.resetForm()
        this.resetValidation()
        callback()
      }
    },
    async loadAllTours () {
      await this.$store.dispatch('allTours', this)
    },
    async loadTours (callback) {
      await this.$store.dispatch('me', this).then(() => {
        if (callback) callback()
      })
    },
    changeOutline (e) {
      if (e.type === 'mouseover') {
        e.target.classList.add('tour-target--outline')
      } else if (e.type === 'mouseout') {
        e.target.classList.remove('tour-target--outline')
      }
    },
    closeMenu () {
      this.$store.state.showTourMenu = false
    },
    // Нахождение пути элемента
    getElementId (el) {
      let elPath = ''
      let elem = el
      while (elem.id !== 'app') {
        let index = Array.prototype.indexOf.call(elem.parentElement.children, elem)
        elPath = ` > *:nth-child(${index + 1})` + elPath
        elem = elem.parentNode
      }
      return 'body > #app' + elPath
    },
    resetForm () {
      Object.keys(this.form).forEach(key => {
        this.form[key].value = null
      })
    },
    resetValidation () {
      Object.keys(this.form).forEach(key => {
        this.form[key].message = null
      })
    }
  }
}
</script>

<style lang="stylus">
.tour {
  &-menu__container {
    &.active {
      .tour-menu__bg {
        display block
      }

      .tour-menu {
        transform translateX(0)
      }
    }
  }

  &-menu__bg {
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 999
    background-color rgba($black, 0.5)
    display none
  }

  &-menu {
    position fixed
    top 0
    right 0
    bottom 0
    z-index 1000
    transition transform 0.3s
    transform translateX(100%)
    display flex
    flex-direction column
    width 100%
    max-width 400px
    height 100vh
    background-color $white

    &__header {
      font-weight: 500;
      font-size 1.2em
      line-height: 21px;
      padding 27px 30px 32px
      border-bottom 1px solid $pinky
    }

    &__body {
      display flex
      flex-direction column
      flex 1
    }

    &__list {
      flex 1
      margin 0
      padding 0
      overflow-y auto
      scrollbar-width: thin;
    }

    &__step {
      display flex
      align-items center
      padding 15px 30px
      font-size: 1.1em
      line-height: 24px;
      border-bottom 1px solid $pinky
      transition 0.2s

      &:hover {
        background-color $gray
      }

      &.tour-add__btn {
        padding 18px 30px
        text-align center
        justify-content center
        gap 5px
        color $orange

        svg {
          width 20px
          height 20px

          path {
            stroke $orange
          }
        }
      }

      &-move {
        margin-right: 10px;
        cursor move
      }

      &-title {
        flex-grow 1
      }

      &-actions {
        display flex
        gap 5px
        margin-left auto

        span {
          display flex
          align-items center
          justify-content center
          width 30px
          min-width 30px
          height 30px
          border-radius: 10px;
          padding 7px
          transition 0.3s
          cursor pointer

          svg {
            width 16px
            height 16px

            path[stroke] {
              stroke $darkgray
            }

            path[fill] {
              fill $darkgray
            }
          }

          &.active,
          &:hover {
            color $white
            background-color $orange

            svg {
              path[stroke] {
                stroke $white
              }

              path[fill] {
                fill $white
              }
            }
          }
        }

        .tour__step-delete {
          &.active,
          &:hover {
            background-color $red
          }
        }
      }
    }

    &__bottom {
      display grid
      grid-template-columns repeat(2, 1fr)
      padding 30px
      gap 16px
      border-top 1px solid $pinky

      button {
        font-size: 15px;
        line-height: 22px;
        text-transform uppercase
      }
    }
  }
}
</style>
